import { ChangeEvent, useState } from 'react';

/** input 상태를 관리하는 함수입니다.*/
export function useForm<T>(initialState: T) {
	const [form, setForm] = useState<T>(initialState);

	const handleChange = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setForm((prevForm) => ({
			...prevForm,
			[name]: value,
		}));
	};

	return { form, setForm, handleChange };
}
